// Generated by Framer (013b13c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["V3UzXRSOV", "ssUT3kZeX"];

const serializationHash = "framer-C2JbU"

const variantClassNames = {ssUT3kZeX: "framer-v-1oixphp", V3UzXRSOV: "framer-v-qh615u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Big: "ssUT3kZeX", Small: "V3UzXRSOV"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "V3UzXRSOV"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "V3UzXRSOV", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 90, intrinsicWidth: 90, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 180, pixelWidth: 180, src: "https://framerusercontent.com/images/bL4acA1Ypt8xJrmDVsxSzgf4kXk.gif"}} className={cx(scopingClassNames, "framer-qh615u", className, classNames)} data-framer-name={"Small"} layoutDependency={layoutDependency} layoutId={"V3UzXRSOV"} ref={refBinding} style={{...style}} {...addPropertyOverrides({ssUT3kZeX: {"data-framer-name": "Big"}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-C2JbU.framer-187da2, .framer-C2JbU .framer-187da2 { display: block; }", ".framer-C2JbU.framer-qh615u { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 180px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 180px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-C2JbU.framer-qh615u { gap: 0px; } .framer-C2JbU.framer-qh615u > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-C2JbU.framer-qh615u > :first-child { margin-left: 0px; } .framer-C2JbU.framer-qh615u > :last-child { margin-right: 0px; } }", ".framer-C2JbU.framer-v-1oixphp.framer-qh615u { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 210px); width: 210px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 180
 * @framerIntrinsicWidth 180
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ssUT3kZeX":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerhzu1JUy_4: React.ComponentType<Props> = withCSS(Component, css, "framer-C2JbU") as typeof Component;
export default Framerhzu1JUy_4;

Framerhzu1JUy_4.displayName = "cursor-flame";

Framerhzu1JUy_4.defaultProps = {height: 180, width: 180};

addPropertyControls(Framerhzu1JUy_4, {variant: {options: ["V3UzXRSOV", "ssUT3kZeX"], optionTitles: ["Small", "Big"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerhzu1JUy_4, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})